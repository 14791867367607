.no-dropdown-before .dropdown-toggle::before,
.main .dropdown-toggle::before,
.no-dropdown-after .dropdown-toggle::after,
.main .dropdown-toggle::after {
	display: none !important;
}
.main-layout a,
.modal a {
	text-decoration: none;
}
.no-shadow-change,
.no-shadow-change:focus {
	box-shadow: inherit !important;
}
.cursor-pointer {
	cursor: pointer;
}
